<template>
    <div class="detail-header">
        <span class="last" @click="$router.back()">{{lastName || '首页'}}</span>
        /
        <span>{{title}}</span>
    </div>
</template>

<script>
// detail-header
export default {
    name: "detail-header",
    props: ['lastName', 'title'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {}
}
</script>

<style scoped lang="less">
.detail-header {
    padding: 0.2rem;
    background-color: #fff;
    border-radius: 0.12rem;
    text-align: left;
    margin-bottom: 0.4rem;
    font-size: 0.4rem;
    & .last {
        cursor: pointer;
        padding: 0.04rem 0.12rem;
        &:hover {
            background-color: #f0f0f0;
            border-radius: 0.12rem;
            color: #0b6195;
            font-weight: 600;
        }
    }
}
</style>
