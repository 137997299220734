<template>
    <div class="index-header box-sizing flex-col">
        <div class="top flex-row flex-j-between flex-a-start">
            
            <div class="flex-row flex-j-start flex-a-start">
                <!--              <div style="font-size: 22px;margin-right: 10px;">就在平度网</div>-->
                <img src="@/assets/images/jiuzaipingdu_title.png"
                     style="margin-right: 10px;margin-top:0.2rem;height: 3rem" />
                <iframe width="350" height="25" frameborder="0" scrolling="no" hspace="0"
                        src="https://i.tianqi.com/?c=code&id=40&py=pingdu" style="margin-top: 0.4rem"></iframe>
                <!--                <iframe width="500" height="28" frameborder="0" scrolling="no" hspace="0"-->
                <!--                         src="https://i.tianqi.com/?c=code&a=getcode&id=40&icon=1"></iframe>-->
            </div>
            <div class="flex-row flex-j-start flex-a-center" style="padding-top: 0.4rem">
                <span class="link" @click="$router.push('/index/office')">我要找工作 <a class="target">hot</a></span>
                <span class="link" @click="$router.push('/index/hire')">我要招人才</span>
                <span v-if="userInfo.Id" class="user-center link" @click="onUserCenterClick">个人中心</span>
                <span v-if="userInfo.Id" class="user-center link" @click="onLogout">退出</span>
                <span v-if="!userInfo.Id&&routerName!=='home'" class="login link" @click="$router.push('/')">登录</span>
                <span v-if="!userInfo.Id" class="login link register">注册
                    <img class="xiaochengxuma" src="@/assets/images/xiaochengxuma.jpg" alt="">
                </span>
            
            </div>
        </div>
        <div class="container">
            <img class="bg" src="../assets/images/WechatIMG154.png" alt="">
            <div v-if="needSearch" class="search-bar flex-row flex-j-end flex-a-center">
                <span class="iconfont icon-chazhaosousuo"></span>
                <input v-model="searchKeyWord" type="text" placeholder="请输入关键字搜索" style="text-indent: 0.8rem"/>
                <span class="search-btn tap" @click="onSearchButtonClick">搜索</span>
            </div>
        
        </div>
        <div class="flex-row flex-j-between flex-a-center head">
            <div class="left flex-row flex-j-between flex-a-center">
                <div :class="`item flex-col flex-j-center flex-a-center ${item.name === routerName ? 'active' : ''}`"
                     v-for="(item,
                index) in
                list"
                     :key="index"
                     @click="onTabClick(item)"
                >{{
                        item
                            .title
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// index-header
export default {
    name: "other-header",
    data() {
        return {
            list: [
                //个人求职、企业招聘、技能培训、创业服务、招聘会、零工之家、余缺调剂、服务机构、通知公告
                {
                    title: '首页',
                    name: 'home',
                },
                {
                    title: '个人求职',
                    name: 'office',
                },
                {
                    title: '企业招聘',
                    name: 'hire',
                },
                {
                    title: '人岗匹配',
                    name: 'jobmatch',
                },
                {
                    title: '技能培训',
                    name: 'train'
                },
                {
                    title: '创业服务',
                    name: 'start-policy'
                },
                {
                    title: '招聘会',
                    name: 'jobfair'
                },
                {
                    title: '零工之家',
                    name: 'work'
                },
                {
                    title: '余缺调剂',
                    name: 'adjust'
                },
                {
                    title: '服务机构',
                    name: 'organize'
                },
                {
                    title: '通知公告',
                    name: 'policy'
                }
            ],
            searchKeyWord: null
        }
    },
    computed: {
        routerName() {
            return this.$route.name;
        },
        needSearch() {
            const needs = ['home', 'office', 'hire'];
            return needs.indexOf(this.routerName) > -1;
        },
        userInfo() {
            return this.$store.state.userInfo || {};
        }
    },
    mounted() {
    },
    methods: {
        onTabClick(item) {
            if (item.name !== this.routerName) {
                this.$router.push({name: item.name})
            }
        },
        onSearchButtonClick() {
            if (window.$onIndexHeaderSearch) {
                window.$onIndexHeaderSearch(this.searchKeyWord);
            }
        },
        onUserCenterClick() {
            if (this.userInfo.MemberType <= 1) {
                this.$router.push("/user/info");
            } else {
                this.$router.push("/company/office-manage");
            }
        },
        onLogout() {
            $app.setStorageSync('token', undefined);
            $app.setStorageSync('userInfo', undefined);
            window.location.reload();
        }
    }
}
</script>

<style scoped lang="less">
.index-header {
    //position: fixed;
    //top: 0;
    //left: 0;
    //right: 0;
    background: #fff;
    //height: 7.2rem;
    //z-index: 999;
    box-shadow: 0 0.06rem 0.1rem 0 rgba(0, 0, 0, 0.1);
    position: relative;
    & .top {
        color: #333;
        //background: #333;
        //height: 1.0rem;
        //line-height: 1.0rem;
        text-align: left;
        padding-left: calc(50vw - 13rem);
        padding-right: calc(50vw - 13rem);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        font-weight: 600;
        & .user-center {
            cursor: pointer;
            border: 0.04rem solid #68dbce;
            border-radius: 1.0rem;
            &:hover {
                font-weight: 600;
                background-color: #68dbce;
                color: #fff;
            }
        }
        & .link {
            margin-left: 0.4rem;
            cursor: pointer;
            padding: 0.04rem 0.2rem;
            transition: all 0.3s ease;
            position: relative;
            &:hover {
                font-size: 0.34rem;
            }
            & .target {
                position: absolute;
                right: -0.2rem;
                bottom: 0.3rem;
                color: #fff;
                padding: 0.0rem 0.1rem;
                border-radius: 1.0rem;
                background-color: #f1676d;
                line-height: 0.3rem;
                font-size: 0.24rem;
            }
        }
        
        & .login {
            border: 0.04rem solid #68dbce;
            border-radius: 1.0rem;
            &:hover {
                font-weight: 600;
                background-color: #68dbce;
                color: #fff;
            }
        }
        & .register {
            position: relative;
            & .xiaochengxuma {
                display: none;
                position: absolute;
                z-index: 999;
                right: 0;
                top: 0.8rem;
                width: 3.0rem;
                height: 3.0rem;
                border-radius: 0.12rem;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
            }
            &:hover {
                & .xiaochengxuma {
                    display: block;
                    
                }
            }
        }
    }
    
    & .container {
        //height: 6.0rem;
        //background-color: #68dbce;
        //padding-left: calc(50vw - 14rem);
        //padding-right: calc(50vw - 14rem);
        position: relative;
        
        & .bg {
            //position: absolute;
            //top: 0.0rem;
            //bottom: 0.0rem;
            ////left: calc(50vw - 14rem);
            ////right: calc(50vw - 14rem);
            //left: 0;
            //right: 0;
            //background: url("../assets/images/WechatIMG129.png");
            ////background-size: 100% 100%;
            //z-index: 0;
            width: 100%;
            height: 7.0rem;
            
        }
        & .search-bar {
            position: absolute;
            right: calc(50vw - 14rem);
            bottom: 0.4rem;
            z-index: 1;
            & .icon-chazhaosousuo {
                position: absolute;
                left: 0.3rem;
                font-size: 0.36rem;
                color: #666;
            }
            
            & input {
                padding: 0.12rem 0.2rem;
                margin-right: 0.2rem;
                border: none;
                box-sizing: border-box;
                width: 6.0rem;
                border-radius: 0.12rem;
            }
            
            & .search-btn {
                padding: 0.12rem;
                background: #fff;
                border-radius: 0.12rem;
                //width: 0.8rem;
                cursor: pointer;
                
                &:hover {
                    color: #68dbce;
                }
            }
        }
        
    }
    
    & .head {
        padding-left: calc(50vw - 14rem);
        padding-right: calc(50vw - 14rem);
        height: 1.2rem;
        background: rgb(11, 97, 149);
    }
    
    & .left {
        height: 100%;
        
        .item {
            width: 2.56rem;
            height: 100%;
            line-height: 100%;
            color: #fff;
            cursor: default;
            position: relative;
            font-size: 0.38rem;
            font-weight: 600;
            background: rgb(11, 97, 149);
            &:after {
                content: " ";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -0.2rem;
                opacity: 0;
                height: 0.08rem;
                background: #68dbce;
                transition: all 0.3s ease;
                pointer-events: none;
            }
            
            &:hover {
                color: #fff;
                
                //&:after {
                //    bottom: 0rem;
                //    opacity: 1;
                //}
            }
        }
        
        .active {
            color: #fff;
            background: rgb(10, 129, 214);
            //&:after {
            //    bottom: 0rem;
            //    opacity: 1;
            //}
        }
    }
}
</style>
